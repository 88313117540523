<template>
  <div class="menuAddForm">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item prop="type" label="类型">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">菜单</el-radio>
          <el-radio :label="2">权限</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="name" label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item prop="pname" label="上级">
        <el-input v-model="form.pname" :readonly="true" v-popover:pnamePopover></el-input>
      </el-form-item>
      <el-form-item prop="path" label="路由" v-show="form.type === 1">
        <el-input v-model="form.path"></el-input>
      </el-form-item>
      <el-form-item prop="sort" label="排序">
        <el-input-number v-model="form.sort" controls-position="right"></el-input-number>
      </el-form-item>
      <el-form-item prop="permissions" label="权限">
        <el-input v-model="form.permissions"></el-input>
      </el-form-item>
      <el-form-item prop="icon" label="图标" v-show="form.type === 1">
        <el-popover v-model="iconListVisible" ref="iconListPopover" placement="bottom-start" trigger="click" popper-class="menuIconPopover">
          <div class="menuIconPopover__inner">
            <div class="menuIconPopover__iconList">
              <el-button
                v-for="(item, index) in iconList"
                :key="index"
                @click="currentIconChanged(item)"
                :class="{ 'is-active': form.icon === item }">
                <svg class="icon-svg" aria-hidden="true"><use :xlink:href="`#${item}`"></use></svg>
              </el-button>
            </div>
          </div>
        </el-popover>
        <el-input v-model="form.icon" v-popover:iconListPopover :readonly="true" placeholder="图标">
          <span slot="prefix" v-if="form.icon">
            <svg class="icon-svg" aria-hidden="true"><use :xlink:href="`#${form.icon}`"></use></svg>
          </span>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>

    <el-popover ref="pnamePopover" v-model="pnamePopoverVisible" trigger="click" placement="bottom-start">
      <el-tree :data="treeData" :props="{ label: 'name', children: 'children' }" node-key="id" :highlight-current="true"
        :expand-on-click-node="false" :default-expanded-keys="[0]" @current-change="treeCurrentChange">
      </el-tree>
    </el-popover>

  </div>
</template>

<script>
  import { getIconList } from '@/lib/utils'
  export default {
    data() {
      return {
        form: {
          type: 1,
          name: '',
          pid: 0,
          pname: '一级菜单',
          path: '',
          sort: 0,
          permissions: '',
          icon: ''
        },
        rules: {
          name: [{
            required: true,
            message: '必填不能为空',
            trigger: 'blur'
          }],
          sort: [{
            required: true,
            message: '必填不能为空',
            trigger: 'blur'
          }]
        },
        iconList: [],
        iconListVisible: false,
        pnamePopoverVisible: false,
        treeData: []
      }
    },
    mounted() {
      this.getTreeData()
      this.iconList = getIconList()
    },
    methods: {
      close() {
        this.$refs.form.resetFields()
        this.$emit('close', true)
      },
      onSubmit() {
        this.$refs.form.validate((valid) => {
          console.log(valid)
          if (!valid) {
            return false;
          }

          this.$http.post('/menu', this.form).then(({
            data: res
          }) => {
            let {
              code,
              msg,
              data
            } = res
            console.log(code, msg, data)

            if (code === 0) {
              this.$message.success('新增成功')
              this.close();
            }

            if (code !== 0) {
              this.$message.error(msg)
            }
          })
        })
      },
      getTreeData() {
        this.$http.get('/menu', {
          params: {}
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)

          if (code === 0 && data) {

            //排序
            data.sort((a, b) => a.sort - b.sort)

            //关联子节点
            data.forEach(item => {
              item.children = data.filter(item2 => item2.pid === item.id)
            })

            //获取根节点
            let root = data.filter(item => item.pid === "0")

            let newRoot = [{
              id: 0,
              pid: null,
              name: '一级菜单',
              children: root
            }]

            let records = newRoot
            this.treeData = records && records.length ? records : []
          }

          if (code !== 0) {
            this.$message.error(msg)
          }
        })
      },
      /**
       * tree当前选中event
       */
      treeCurrentChange(data) {
        this.form.pid = data.id
        this.form.pname = data.name
        // 隐藏弹出框
        this.pnamePopoverVisible = false
      },
      // 图标选中
      currentIconChanged (icon) {
        this.$set(this.form, 'icon', icon)
        this.iconListVisible = false
      }
    }
  }
</script>

<style lang="scss">
.menuIconPopover {
  width: 464px;
  height: 284px;
  padding: 0;
  overflow: auto;
  &__inner {
    width: 460px;
    height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__iconList {
    width: 458px;
    padding: 0;
    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;
      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
}
</style>
