<template>
  <div>
    <el-card>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addDialogVisible=true">新增</el-button>
        </el-form-item>
      </el-form>

      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;" row-key="id" border
        :default-expand-all="false" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column header-align="center" align="left" prop="name" label="名称">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="type" label="类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 1" size="small">菜单</el-tag>
            <el-tag v-if="scope.row.type === 2" size="small" type="info">权限</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="icon" label="图标">
          <template slot-scope="scope">
            <svg class="icon-svg" aria-hidden="true">
              <use :xlink:href="`#${scope.row.icon}`"></use>
            </svg>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="path" label="路由">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="permissions" label="权限">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="sort" label="排序">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="updateHandle(scope.row.id)">修改</el-button>
            <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :visible.sync="addDialogVisible" title="新增">
      <add v-if="addDialogVisible" @close="addClose"></add>
    </el-dialog>

    <el-dialog :visible.sync="updateDialogVisible" title="修改">
      <update v-if="updateDialogVisible" :id="updateId" @close="updateClose"></update>
    </el-dialog>
  </div>
</template>

<script>
  import add from './add'
  import update from './update'
  export default {
    components: {
      add,
      update
    },
    data() {
      return {
        tableLoading: false,
        tableData: [],
        addDialogVisible: false,
        updateDialogVisible: false,
        updateId: null
      }
    },
    activated() {
      this.getTableData()

    },
    methods: {
      getTableData() {
        this.tableLoading = true
        this.$http.get('/menu', {
          params: {}
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)
          if (code === 0 && data) {

            //排序
            data.sort((a, b) => a.sort - b.sort)

            //关联子节点
            data.forEach(item => {
              item.children = data.filter(item2 => item2.pid === item.id)
            })

            //获取根节点
            let root = data.filter(item => item.pid === "0")

            let records = root
            this.tableData = records && records.length ? records : []
          }
          if (code !== 0) {
            this.$message.error(msg)
          }
          this.tableLoading = false
        })
      },
      /**
       * 新增功能关闭事件
       */
      addClose() {
        this.addDialogVisible = false
        this.getTableData()
      },
      /**
       * 修改功能关闭事件
       */
      updateClose() {
        this.updateDialogVisible = false
        this.getTableData()
      },
      updateHandle(id) {
        console.log(id)
        this.updateId = id
        this.updateDialogVisible = true
      },
      deleteHandle(id) {
        this.$http.delete('/menu/' + id).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)

          if (code === 0) {
            this.$message.success('删除成功')
            this.getTableData()
          }

          if (code !== 0) {
            this.$message.error(msg)
          }
        })
      }
    }
  }
</script>

<style>
</style>
